import React, { useEffect, useState, useContext } from "react";
import { createPortal } from "react-dom";
import { AxiosResponse } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faSearch } from "@fortawesome/pro-light-svg-icons";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";

import API from "../../api/_config";
import { ImageValueType } from "../../utils/types";
import { ManageBlockDetailContext } from "../../store/ManageBlockContext";
import FileInput, { FileInputPlaceholder, UploadedImage } from "./FileInput";
import LanguageBox from "../LanguageBox";
import Button, { ButtonType } from "../Button";
import Loading, { LoadingMode } from "../Loading";
import InputField from "./InputField";

type RemoteFileType = ImageValueType & { name: string };

const FilePicker: React.FC<{
  label: string;
  value: ImageValueType | null;
  onFileDrop: (imageValue: ImageValueType) => void;
  onTrashClick: () => void;
  type?: string;
}> = ({ label, value, onFileDrop, onTrashClick, type }) => {
  const [showModal, setShowModal] = useState(false);

  const clickHandler = () => {
    if (value) return;

    setShowModal(true);
  };

  const closeModal = () => setShowModal(false);

  return (
    <div>
      <label className="text-sm font-light text-black2B">{label}</label>
      <div
        className={`rounded-[5px] border-[0.5px] border-dashed border-secondary-200 relative overflow-hidden grid place-items-center my-2 px-5 py-10 bg-white ${
          value ? "cursor-default" : "cursor-pointer"
        }`}
        onClick={clickHandler}
      >
        <FileInputPlaceholder label="Upload" />
        {value && <UploadedImage value={value} onTrashClick={onTrashClick} />}
      </div>
      {showModal && <FilePickerModal closeModal={closeModal} onFileDrop={onFileDrop} type={type} />}
    </div>
  );
};

const FilePickerModal: React.FC<{ closeModal: () => void; onFileDrop: (value: ImageValueType) => void; type?: string }> = ({
  closeModal,
  type,
  onFileDrop,
}) => {
  const { defaultLanguage, languages } = useContext(ManageBlockDetailContext);

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const [uploadedFileValue, setUploadedFileValue] = useState<ImageValueType | null>(null);
  const [language, setLanguage] = useState<string>(defaultLanguage);
  const [alt, setAlt] = useState(new Map(languages.map((_language) => [_language, ""])));

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRemoteFile, setSelectedRemoteFile] = useState<RemoteFileType>();

  const {
    loading: getFilesLoading,
    hasError: getFilesHasError,
    response: getFilesResponse,
    getRequest: getFiles,
  } = useFilePickerApi(`/file-list/?search=${searchQuery}`);

  const {
    loading: deleteFileLoading,
    hasError: deleteFileHasError,
    response: deleteFileResponse,
    deleteRequest: deleteFile,
  } = useFilePickerApi(`/files/${uploadedFileValue?.id}/`);

  const {
    loading: patchFileLoading,
    hasError: patchFileHasError,
    response: patchFileResponse,
    patchRequest: patchFile,
  } = useFilePickerApi(`/files/${uploadedFileValue?.id}/`);

  useEffect(() => {
    const timeout = setTimeout(() => getFiles(), 1000);

    return () => clearTimeout(timeout);
  }, [searchQuery]);

  useEffect(() => {
    if (deleteFileResponse?.status === 204) closeModal();
  }, [deleteFileResponse]);

  useEffect(() => {
    if (patchFileResponse?.status === 200) {
      const { id, thumbnail, url } = uploadedFileValue!;
      onFileDrop({ id, thumbnail, url });
      closeModal();
    }
  }, [patchFileResponse]);

  const renderFirstTab = () => {
    return (
      <div className="px-10">
        <FileInput
          label="File"
          type={type}
          value={uploadedFileValue}
          onTrashClick={() => setUploadedFileValue(null)}
          onFileUpload={(value) => setUploadedFileValue(value)}
        />
        <LanguageBox
          defaultLang={defaultLanguage ?? languages[0]}
          languagesArr={languages}
          onChange={(_language) => setLanguage(_language)}
          className="mt-6"
        />
        <InputField
          className="mt-2"
          type="T"
          value={alt.get(language)!}
          onChange={(value) =>
            setAlt((_alt) => {
              const newAlt = new Map(_alt);
              newAlt.set(language, value ?? "");
              return newAlt;
            })
          }
          label="Alt"
          isTextArea
        />
      </div>
    );
  };

  const renderSecondTab = () => {
    const remoteFileClickHandler = (file: RemoteFileType) => {
      if (file.id === selectedRemoteFile?.id) setSelectedRemoteFile(undefined);
      else setSelectedRemoteFile(file);
      getFiles();
    };

    const renderRemoteFiles = () => {
      if (getFilesLoading)
        return (
          <div className="mt-6 px-10 grid place-items-center">
            <Loading loadingMode={LoadingMode.Button} />
          </div>
        );
      if (getFilesHasError) return <p className="mt-6 px-10 text-red-500 text-sm">An error has occurred.</p>;

      const remoteFiles = getFilesResponse!.data as RemoteFileType[];
      if (remoteFiles.length > 0) {
        return (
          <ul className="mt-6 px-10 flex-1 overflow-y-scroll flex flex-col gap-y-6">
            {remoteFiles.map((remoteFile) => (
              <RemoteFile
                key={remoteFile.id}
                remoteFile={remoteFile}
                remoteFileClickHandler={remoteFileClickHandler}
                selected={remoteFile.id === selectedRemoteFile?.id}
              />
            ))}
          </ul>
        );
      } else {
        return <p className="mt-6 px-10 text-black2B text-sm">No file found with your search criteria.</p>;
      }
    };

    return (
      <div className="flex flex-col h-full">
        {selectedRemoteFile && (
          <ul className="mb-6 px-10">
            <RemoteFile remoteFile={selectedRemoteFile} remoteFileClickHandler={remoteFileClickHandler} selected />
          </ul>
        )}
        <form className="px-10">
          <div className="relative">
            <FontAwesomeIcon icon={faSearch} className="text-secondary-200 absolute left-4 top-1/2 -translate-y-1/2" />
            <input
              type="text"
              value={searchQuery}
              className="w-full border-[1px] rounded-[5px] border-secondary-200 py-2 pr-4 pl-9 text-sm outline-none text-black2B placeholder:text-secondary-200"
              placeholder="Search"
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </form>
        {renderRemoteFiles()}
      </div>
    );
  };

  const cancelClickHandler = async () => {
    if (getFilesLoading || deleteFileLoading || patchFileLoading) return;

    if (uploadedFileValue) deleteFile();
    else closeModal();
  };

  const selectClickHandler = () => {
    if (getFilesLoading || deleteFileLoading || patchFileLoading) return;

    if (activeTabIndex === 0) {
      if (!uploadedFileValue) return;

      const body = new FormData();
      body.append("alternative", JSON.stringify(Object.fromEntries(alt)));
      patchFile(body);
    } else {
      if (!selectedRemoteFile) return;

      if (uploadedFileValue) deleteFile();

      const { id, thumbnail, url } = selectedRemoteFile;
      onFileDrop({ id, thumbnail, url });
      closeModal();
    }
  };

  const getSelectDisabled = () => (activeTabIndex === 0 && !uploadedFileValue) || (activeTabIndex === 1 && !selectedRemoteFile);

  const render = () => {
    return (
      <div className="fixed top-0 left-0 w-screen h-screen grid place-items-center z-[60]" style={{ background: "rgba(0, 0, 0, 0.3)" }}>
        <section className="bg-white w-[90%] max-w-[490px] h-[714px] rounded-lg pb-10 relative flex flex-col">
          <button className="absolute w-6 h-6 top-2 right-10 text-black2B grid place-items-center" onClick={cancelClickHandler}>
            {deleteFileLoading ? <Loading loadingMode={LoadingMode.Button} /> : <FontAwesomeIcon icon={faClose} />}
          </button>
          <header className="flex border-b-2 border-primary mt-10">
            {["Upload a new file", "Choose an existing file"].map((tab, index) => (
              <button
                key={index}
                className={`flex-1 text-center font-light py-1 duration-300 ${
                  activeTabIndex === index ? "text-white bg-primary" : "text-black2B bg-transparent"
                }`}
                onClick={() => setActiveTabIndex(index)}
              >
                {tab}
              </button>
            ))}
          </header>
          <main className="flex-1 my-6 h-0">{activeTabIndex === 0 ? renderFirstTab() : renderSecondTab()}</main>
          <footer className="flex justify-end gap-x-4 px-10">
            <Button type={ButtonType.SECONDARY} onClick={cancelClickHandler} showLoading={deleteFileLoading}>
              Cancel
            </Button>
            <Button type={ButtonType.PRIMARY} onClick={selectClickHandler} disable={getSelectDisabled()} showLoading={patchFileLoading}>
              Select
            </Button>
          </footer>
        </section>
      </div>
    );
  };

  return createPortal(render(), document.getElementById("overlay-container")!);
};

const RemoteFile: React.FC<{ remoteFile: RemoteFileType; selected: boolean; remoteFileClickHandler: (file: RemoteFileType) => void }> = ({
  remoteFile,
  selected,
  remoteFileClickHandler,
}) => {
  const { id, thumbnail, name } = remoteFile;

  return (
    <li key={id} className="flex justify-between items-center gap-x-1 cursor-pointer" onClick={() => remoteFileClickHandler(remoteFile)}>
      <div className="flex flex-1 items-center gap-x-4">
        <img src={thumbnail} alt="Remote File" className="w-20 h-20 object-cover rounded-[5px]" />
        <p className="text-sm text-black2B flex-1 overflow-hidden whitespace-nowrap overflow-ellipsis">{name}</p>
      </div>
      <button
        className={`w-5 h-5 text-xs rounded-full grid place-items-center border-[1px] ${
          selected ? "border-primary bg-primary" : "border-secondary-200 bg-white"
        }`}
      >
        {selected && <FontAwesomeIcon icon={faCheck} className="text-white" />}
      </button>
    </li>
  );
};

export const useFilePickerApi = (url: string) => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [response, setResponse] = useState<AxiosResponse | null>(null);

  const getRequest = async () => {
    setHasError(false);
    setLoading(true);

    try {
      const response = await API.get(url);
      setResponse(response);
    } catch (_) {
      setHasError(true);
    }

    setLoading(false);
  };

  const patchRequest = async (body: FormData) => {
    setHasError(false);
    setLoading(true);

    try {
      const response = await API.patch(url, body);
      setResponse(response);
    } catch (_) {
      setHasError(true);
    }

    setLoading(false);
  };

  const deleteRequest = async () => {
    setHasError(false);
    setLoading(true);

    try {
      const response = await API.delete(url);
      setResponse(response);
    } catch (_) {
      setHasError(true);
    }

    setLoading(false);
  };

  return { loading, hasError, response, getRequest, patchRequest, deleteRequest };
};

export default FilePicker;
