import { useEffect, useRef, useState, useContext } from "react";
import { Link } from "react-router-dom";

import { ManageBlockDetailContext } from "../../store/ManageBlockContext";
import LanguageBox from "../LanguageBox";

const StyleSidebarLayout: React.FC<{
  contentName?: string;
  contentId?: string;
  blockName?: string;
  sidebar: React.ReactNode;
  responsiveSidebar: React.ReactNode;
  children: React.ReactNode;
  languages?: string[];
}> = ({ contentName, contentId, blockName, sidebar, children, responsiveSidebar, languages }) => {
  const [sidebarPosition, setSidebarPosition] = useState({ top: 0, left: 0 });

  const mainRef = useRef<HTMLElement>(null);
  const languagesRef = useRef<HTMLDivElement>(null);
  const breadCrumbRef = useRef<HTMLDivElement>(null);

  const { defaultLanguage, setLanguage } = useContext(ManageBlockDetailContext);

  useEffect(() => {
    const _setSidebarPosition = () => {
      if (mainRef && mainRef.current) {
        const { top, left, width } = mainRef.current.getBoundingClientRect();
        setSidebarPosition({
          top: top,
          left: left + width + 16,
        });
      }
    };

    _setSidebarPosition();

    const windowScrollHandler = () => {
      let offsetTop = 0;
      if (languagesRef && languagesRef.current) {
        offsetTop = languagesRef.current.offsetTop;
      }

      if (mainRef && mainRef.current) {
        const { top, left, width } = mainRef.current.getBoundingClientRect();
        setSidebarPosition({
          top: top - window.scrollY < 116 ? 116 : top - window.screenY,
          left: left + width + 16,
        });
      }
      // const _sidebarTop = mainRef.current!.getBoundingClientRect().top - window.scrollY;
      // setSidebarPosition(_sidebarTop < 116 ? { ...sidebarPosition, top: 116 } : { ...sidebarPosition, top: _sidebarTop });

      if (languagesRef.current) {
        if (window.scrollY >= offsetTop) {
          languagesRef.current.style.position = "fixed";
          languagesRef.current.style.zIndex = "50";
          languagesRef.current.style.top = "105px";
        } else {
          languagesRef.current.style.position = "static";
          languagesRef.current.style.top = "auto";
          offsetTop = languagesRef.current.offsetTop;
        }
      }
    };

    const windowResizeHandler = () => _setSidebarPosition();

    window.addEventListener("scroll", windowScrollHandler);
    window.addEventListener("resize", windowResizeHandler);

    return () => {
      window.removeEventListener("scroll", windowScrollHandler);
      window.removeEventListener("resize", windowResizeHandler);
    };
  }, [mainRef]);

  const contentLinkClickHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    // If contentId === '2', it means the content is Footer,
    // So we don't navigate anywhere on content link's click.
    if (contentId === "2") e.preventDefault();
  };

  return (
    <div className={`w-[95%] mx-auto ${contentName && contentId && "py-1"}`}>
      {contentName && contentId && (
        <header>
          {contentName !== "Cookie" && (
            <div ref={breadCrumbRef} className="flex breadcrumb-text mt-10">
              <Link to="/content">Content</Link>
              <span className="mx-1">&gt;</span>
              <Link
                to={contentName === "Header" ? "/menu" : `/content/${contentId}`}
                // If content is Footer, It's not clickable.
                // So the cursor must not be a pointer.
                className={contentId === "2" ? "cursor-default" : "cursor-pointer"}
                onClick={contentLinkClickHandler}
              >
                {contentName === "Header" ? "Menu" : contentName}
              </Link>
              <span className="mx-1">&gt;</span>
              {contentName !== "Header" && (
                <>
                  <Link to={`/content/${contentId}/blocks`}>Manage Blocks</Link>
                  <span className="mx-1 breadcrumb-last-text">&gt;</span>
                </>
              )}
              <p className="breadcrumb-last-text">Manage {blockName}</p>
            </div>
          )}

          <div className="flex flex-wrap gap-6 items-center py-10" style={{ boxShadow: "0 8px 4px -4px rgba(0, 0, 0, 0.1)" }}>
            <h3>Manage {blockName}</h3>
            {languages && (
              <div ref={languagesRef}>
                <LanguageBox
                  languagesArr={languages}
                  defaultLang={defaultLanguage ?? languages[0]}
                  onChange={(lang) => setLanguage(lang)}
                  className="z-10 border border-primary"
                />
              </div>
            )}
          </div>
        </header>
      )}
      <div>
        <aside
          className="w-[282px] fixed bottom-10 hidden 720:block"
          style={{ top: `${sidebarPosition.top}px`, left: `${sidebarPosition.left}px` }}
        >
          {sidebar}
        </aside>
        {responsiveSidebar}
        <main className="mt-6 mr-7 720:mr-[298px]" ref={mainRef}>
          {children}
        </main>
      </div>
    </div>
  );
};

export default StyleSidebarLayout;
