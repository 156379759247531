import { useParams } from "react-router-dom";
import SearchInput from "../../../../inputs/SearchInput";
import Button, { ButtonType } from "../../../../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { DropResult } from "react-beautiful-dnd";
import { useEffect, useState } from "react";
import {
  getPostType,
  getPostTypeFields,
  createPostTypeFiled,
  updatePostTypeField,
  deletePostTypeField,
  updatePostTypeFieldOrder,
} from "../../../../../api/postType";
import AddOrEditValueModal from "../AddOrEditPostTypeModal";
import DeleteDictValueModal from "../DeletePostFiledModal";
import { Snackbar, SnackbarContent } from "@mui/material";
import PageContainer from "../../../../layout/PageContainer";
import Table from "./Table";
import Line from "../../../Line";
import { PostTypeType } from "../../manageValue/ManagePostType";
import Modal from "../../../../Modal";

export type PostTypeFieldType = {
  id: number;
  name: string;
  isActive: boolean;
  fieldType: string;
  showFieldInNewsletter: boolean;
  order: number;
  showFieldInPublicHeader: boolean;
  postType?: PostTypeType;
  publicStyle?: any;
  newsletterPublicStyle?: any;
  isUsed: boolean;
};

export enum ManagePostTypeType {
  ManageValue = "ManageValue",
  ManagePostCategory = "ManagePostCategory",
}

export default function ManagePostTypeFields() {
  const columns = [
    { Header: "Name", accessor: "name" },
    { Header: "Active", accessor: "active" },
    { Header: "Field Type", accessor: "field_type" },
    { Header: "Show In Header", accessor: "show_summary_in_public_header" },
    { Header: "Show In Newsletter", accessor: "show_summary_in_newsletter" },
    { Header: " ", accessor: "" },
  ];

  const params = useParams();

  const [allValues, setAllValues] = useState<Array<PostTypeFieldType>>([]);
  const [languages, setLanguages] = useState<Array<string>>([]);
  const [defaultLanguage, setDefaultLanguage] = useState<string>("en");
  const [currentPostType, setCurrentPostType] = useState<PostTypeType>();
  const [fieldTypes, setFieldTypes] = useState<Array<any>>([]);
  const [showingValues, setShowingValues] = useState<Array<PostTypeFieldType>>([]);
  const [searchPhrase, setSearchPhrase] = useState<string | null>(null);
  const [errorModal, setErrorModal] = useState({ show: false, msg: "" });
  const [showAddOrEditValueModal, setShowAddOrEditValueModal] = useState<{
    dictValue?: PostTypeFieldType;
    showModal: boolean;
  }>({
    showModal: false,
  });

  const [showSnackbar, setShowSnackbar] = useState<{
    showSnack: boolean;
    isSuccess: boolean;
    message?: string;
  }>({
    showSnack: false,
    isSuccess: false,
  });

  const [isDraggable, setIsDraggable] = useState(false);
  const [showOptionsMenu, setShowOptionsMenu] = useState<{
    dictValue?: PostTypeFieldType;
    showModal: boolean;
  }>({
    showModal: false,
  });
  const [showDeleteModal, setShowDeleteModal] = useState<{
    dictValue?: PostTypeFieldType;
    showModal: boolean;
  }>({
    showModal: false,
  });

  useEffect(() => {
    if (params.postTypeId) {
      getPostType(params.postTypeId).then((res) => {
        setCurrentPostType(res.data.PostType);
      });
    }
  }, [params]);

  const refetchData = () => {
    if (params.postTypeId) {
      getPostTypeFields(params.postTypeId).then((res) => {
        setAllValues(res.data.data);
        setLanguages(res.data.languages);
        setDefaultLanguage(res.data.defaultLanguage);
        setShowingValues(res.data.data);
        if (res.data.fieldType) {
          setFieldTypes(res.data.fieldType);
        }
      });
    }
  };

  useEffect(() => {
    refetchData();
  }, [params]);

  useEffect(() => {
    search();
  }, [searchPhrase]);

  const search = () => {
    if (searchPhrase) {
      const targetData = allValues.filter((dict) => {
        return JSON.stringify(dict.name).toLowerCase().includes(searchPhrase?.toLowerCase());
      });
      setShowingValues(targetData);
    } else {
      setShowingValues(allValues);
    }
  };

  const onOptionClick = (dictValue: PostTypeFieldType, selectedValue: PostTypeOptionType) => {
    switch (selectedValue) {
      case PostTypeOptionType.Edit:
        setShowAddOrEditValueModal({ dictValue: dictValue, showModal: true });
        break;
      case PostTypeOptionType.Delete:
        setShowDeleteModal({ dictValue: dictValue, showModal: true });
        break;
    }
  };

  const operationCompleted = (_isSuccess: boolean, _message: string) => {
    if (_isSuccess) {
      // getAllValues();
    }

    setShowSnackbar({
      showSnack: true,
      isSuccess: _isSuccess,
      message: _message,
    });
    refetchData();
  };

  const updateData = (result: DropResult, pageIndex: number, pageSize: number) => {
    if (!result.destination) return;

    const updatedData = Array.from(showingValues);
    const [reOrderedFriend] = updatedData.splice(pageIndex * pageSize + result.source.index, 1);
    updatedData.splice(pageIndex * pageSize + result.destination.index, 0, reOrderedFriend);

    // setShowLoading(true);
    setShowingValues(updatedData);
  };

  return (
    <PageContainer>
      <div className="w-full">
        <div className="888:flex justify-between flex-wrap mx-10 py-[44px]">
          <h3>
            {"Manage Additional Fields - "}
            {currentPostType?.name}
          </h3>

          <div className="616:flex flex-wrap 888:mt-0 mt-2 justify-end 1125:w-fit w-full">
            <SearchInput
              searchHandler={(searchedPhrase) => {
                searchedPhrase.length !== 0 ? setIsDraggable(true) : setIsDraggable(false);
                setSearchPhrase(searchedPhrase);
              }}
              className=" mr-4 888:w-[400px]"
            />
            <div className="flex flex-wrap 690:mt-0 mt-2">
              <Button
                className="h-10 w-[125px] mt-2 616:mt-0"
                type={ButtonType.PRIMARY}
                onClick={() => setShowAddOrEditValueModal({ showModal: true })}
              >
                <FontAwesomeIcon icon={faPlus} className="mr-1" />
                Add Field
              </Button>
            </div>
          </div>
        </div>

        <Line />

        {showingValues?.length > 0 ? (
          <div className="mx-12 mt-6">
            <Table
              columns={columns}
              data={showingValues}
              setShowOptionsMenu={setShowOptionsMenu}
              showOptionsMenu={showOptionsMenu}
              onOptionClick={onOptionClick}
              updateData={updateData}
              update_order={(id, _oldOrder, _newOrder) => {
                if (params.postTypeId) {
                  updatePostTypeFieldOrder(params.postTypeId, id, _oldOrder, _newOrder);
                }
              }}
              isDragDisabled={isDraggable}
              droppableId={"postCat"}
            />
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center">
            <img className="self-center mt-16" src={require("../../../../../images/no_data_img.png")} width={572} height={406} />
            <label className="font-base text-lg">{"No posts are added"}</label>
          </div>
        )}

        {showAddOrEditValueModal.showModal && (
          <AddOrEditValueModal
            languages={languages}
            defaultLanguage={defaultLanguage}
            fieldTypes={fieldTypes.map((t) => {
              return { ...t, key: t.value };
            })}
            postTypeFieldValue={showAddOrEditValueModal.dictValue}
            onClose={() =>
              setShowAddOrEditValueModal({
                dictValue: undefined,
                showModal: false,
              })
            }
            onSave={(postTypeValue: PostTypeFieldType) => {
              if (postTypeValue.id === -1) {
                if (params.postTypeId) {
                  createPostTypeFiled(params?.postTypeId, {
                    name: postTypeValue.name,
                    showFieldInPublicHeader: postTypeValue.showFieldInPublicHeader,
                    field_type: postTypeValue.fieldType,
                    publicStyle: postTypeValue.publicStyle,
                    newsletterPublicStyle: postTypeValue.newsletterPublicStyle,
                    showFieldInNewsletter: postTypeValue.showFieldInNewsletter,
                  }).then((res) => {
                    operationCompleted(res.status === 201, "Post Type Field created successfully");
                    refetchData();
                  });
                }
              } else {
                if (params.postTypeId) {
                  updatePostTypeField(params.postTypeId, postTypeValue).then((res) => {
                    operationCompleted(res.status === 200, "Post Type Field Updated successfully");
                    refetchData();
                  });
                }
              }

              setShowAddOrEditValueModal({
                dictValue: undefined,
                showModal: false,
              });
            }}
          />
        )}

        {
          <Modal showModal={errorModal.show}>
            <div>
              <div>{errorModal.msg}</div>
              <div className="w-full flex justify-center mt-4">
                <Button onClick={() => setErrorModal({ msg: "", show: false })} type={ButtonType.PRIMARY}>
                  Ok
                </Button>
              </div>
            </div>
          </Modal>
        }

        {showDeleteModal.showModal && (
          <DeleteDictValueModal
            postType={showDeleteModal.dictValue!}
            onPositiveClicked={(modalType) => {
              if (params.postTypeId) {
                deletePostTypeField(params.postTypeId, showDeleteModal.dictValue!.id)
                  .then((res) => operationCompleted(res.status === 204, "Field Type removed"))
                  .catch((error) => {
                    if (error.response.status === 400) {
                      setErrorModal({ msg: error.response.data.error, show: true });
                    }
                  });

                setShowDeleteModal({ dictValue: undefined, showModal: false });
              }
            }}
            onNegativeClicked={() => setShowDeleteModal({ dictValue: undefined, showModal: false })}
          />
        )}

        <Snackbar
          open={showSnackbar.showSnack}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          sx={{
            "& .MuiSnackbarContent-root": {
              backgroundColor: `${showSnackbar.isSuccess ? "#03A678" : "#D11C1C"} `,
            },
            "& .MuiSnackbarContent-message": {
              fontSize: "16px",
              fontFamily: "'Open Sans', sans-serif",
              fontWeight: "100",
            },
          }}
          onClose={() => {
            setShowSnackbar({ ...showSnackbar, showSnack: false });
          }}
        >
          <SnackbarContent message={<p>{showSnackbar.message}</p>} />
        </Snackbar>
      </div>
    </PageContainer>
  );
}

export enum PostTypeOptionType {
  Edit,
  Delete,
  ManageAdditionalFields,
}
