import React, { useState } from "react";
import InputField from "../inputs/InputField";
import { isValidURL } from "../../utils/utils";
import { ImageValueType } from "../../utils/types";
import FilePicker from "../inputs/FilePicker";

const DataBox: React.FC<{
  title: string;
  logoRedirectLink?: string;
  image: ImageValueType | null;
  onFileUpload: (logoImage: ImageValueType) => void;
  onTrashClick: () => void;
  redirectLinkOnChange?: (value: string | null) => void;
  hasRedirectLink: boolean;
}> = ({ title, logoRedirectLink, image, onFileUpload, onTrashClick, redirectLinkOnChange, hasRedirectLink }) => {
  const [errorState, setErrorState] = useState<any>(true);

  return (
    <div className="bg-secondary-100 px-4 py-6 flex flex-col">
      <div>
        <div>{title}</div>
        <div className="font-light mt-2">You can select just one file.</div>
        <div className="mt-6">
          <FilePicker label="File" value={image} onFileDrop={onFileUpload} onTrashClick={onTrashClick} />
        </div>
        {hasRedirectLink && (
          <div className="w-full mt-4">
            <InputField
              label="Logo URL"
              onChange={(value) => {
                redirectLinkOnChange!(value);
                setErrorState(isValidURL(value!));
              }}
              type="U"
              value={logoRedirectLink ? logoRedirectLink : ""}
              errorMessage={errorState ? "" : "The url is invalid."}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DataBox;
