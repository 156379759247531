import React from "react";
import Loading, { LoadingMode } from "./Loading";

export enum ButtonType {
  PRIMARY,
  SECONDARY,
  TERTIARY,
  QUATERNARY,
  ERROR,
}

const Button: React.FC<{
  type: ButtonType;
  onClick: () => void;
  children: React.ReactNode;
  className?: string;
  disable?: boolean;
  showLoading?: boolean;
  style?: React.CSSProperties;
  onBlur?: () => void;
}> = ({ type, onClick, children, className, disable, showLoading, style, onBlur }) => {
  let _className;
  switch (type) {
    case ButtonType.PRIMARY:
      _className = `${
        disable ? "cursor-default bg-secondary-200 border-secondary-200" : "bg-primary border-primary"
      } text-white border-[1px]`;
      style = {
        boxShadow: "0 10px 20px 0 rgba(0, 0, 0, 0.15)",
      };
      break;
    case ButtonType.SECONDARY:
      _className = "text-primary border-[1px] border-primary";
      break;
    case ButtonType.TERTIARY:
      _className = "bg-white text-gray border-[1px] border_[#C4C4C4]";
      break;
    case ButtonType.QUATERNARY:
      _className = "bg-white text-secondary-300 border-[1px] border-secondary-200";
      style = {
        boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.161)",
      };
      break;
    case ButtonType.ERROR:
      _className = "bg-Red text-white";
      break;
  }

  return (
    <button
      onClick={showLoading ? undefined : onClick}
      className={`py-2 px-4 rounded-[5px] duration-300 ${_className} ${className}`}
      disabled={disable}
      style={style}
      onBlur={onBlur}
    >
      {showLoading ? <Loading loadingMode={LoadingMode.Button} /> : children}
    </button>
  );
};

export default Button;
